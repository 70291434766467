<template>
 <div v-if="internalSite" id="internalSiteIdentity" class="alert border border-dark" :class="{'d-none': hide}">
   <div class="row">
     <div class="col">
       <a @click="close" href="#"><i class="fa fa-times"></i></a>
     </div>
   </div>
   <div class="row">
     <div class="col">
       <strong>Branch: </strong>
       <br />
       {{ branch }}
       <hr />
       <strong>Linked Site: </strong>
       <br />
       <a :href="linkedToSite" target="_blank">{{ linkedToSite }}</a>
       <hr />
       <strong>API Endpoint: </strong>
       <br />
       {{ apiEndpoint }}
       <hr />
       <strong>Database: </strong><br />{{ database }}
     </div>
   </div>
 </div>
</template>

<script>
const MISSING_ENV = 'missing env';

export default {
  name: "InternalSiteIdentity",
  data: () => ({
    'hide': false,
  }),
  computed: {
    showConfig() {
      return process.env.VUE_APP_SHOW_CONFIG;
    },
    internalSite() {
      return typeof process.env.VUE_APP_SHOW_CONFIG !== 'undefined' && process.env.VUE_APP_SHOW_CONFIG==="true";
    },
    branch() {
      return process.env.VUE_APP_BRANCH ?? MISSING_ENV;
    },
    linkedToSite() {
      return process.env.VUE_APP_LINKED_SYSTEM_URL ?? MISSING_ENV;
    },
    apiEndpoint() {
      return process.env.VUE_APP_API_ENDPOINT ?? MISSING_ENV;
    },
    database() {
      return process.env.VUE_APP_DATABASE ?? MISSING_ENV;
    },
  },
  methods: {
    close() {
      this.hide = true;
    }
  }
};
</script>
<style>
/*#internalSiteIdentity {*/
/*  position: fixed;*/
/*  right:0;*/
/*  bottom:44px;*/
/*  width: 380px;*/
/*  height: 135px;*/
/*  background-color: rgba(220, 220, 220, 60%);*/
/*}*/
hr {
  margin: 1px 0;
}
</style>
